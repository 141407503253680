<template>
    <!-- <nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/galleria">Galleria</router-link>
    </nav> -->

    <nav class="navbar navbar-expand-lg bg-body-tertiary mb-3">
        <div class="container">
            <a class="navbar-brand" @click="$router.push('/')">Galleria Premio Forlano</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link" @click="$router.push('/')">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="$router.push('/galleria')">Galleria</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" @click="$router.push('/portfolio')">Portfolio</a>
                    </li> -->
                    
                </ul>
                <!-- <form class="d-flex" role="search">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success" type="submit">Search</button>
                </form> -->
            </div>
        </div>
    </nav>
</template>